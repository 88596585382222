:root {
  --container-bg-color: #333;
  --left-bg-color: rgba(223, 159, 39, 0.7);
  --left-button-hover-color: rgba(255, 255, 255, 0.7);
  --right-bg-color: rgba(43, 43, 43, 0.8);
  --right-button-hover-color: rgba(223, 159, 39, 0.7);
  --hover-width: 75%;
  --other-width: 25%;
  --speed: 1000ms;
}

html, body {
  padding:0;
  margin:0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

h1 {
  font-size: 4rem;
  font-family: 'Pacifico', cursive;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.buttonLink {
  display: block;
  position: absolute;
  left: 50%;
  top: 40%;
  height: 2.5rem;
  padding-top: 1.3rem;
  padding-bottom: 2rem;
  width: 15rem;
  text-align: center;
  color: #fff;
  border: #fff solid 0.2rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  transform: translateX(-50%);
}

.split.left .buttonLink:hover {
  background-color: var(--left-button-hover-color);
  border-color: var(--left-button-hover-color);
  color: rgba(0, 0, 0, 0.7);
}

.split.right .buttonLink:hover {
  background-color: var(--right-button-hover-color);
  border-color: var(--right-button-hover-color);
  color: rgba(0, 0, 0, 0.7);
}

.split {
  position: absolute;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.split.left {
  left:0;
  background: url('./computer2.jpg') center center no-repeat;
  background-size: cover;
}

.split.left:before {
  position:absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: var(--left-bg-color);
}

.split.right {
  right:0;
  background: url('./Photography.jpg') center center no-repeat;
  background-size: cover;
}

.split.right:before {
  position:absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: var(--right-bg-color);
}

.split.left, .split.right, .split.right:before, .split.left:before {
  transition: var(--speed) all ease-in-out;
}

.hover-left .left {
  width: var(--hover-width);
}

.hover-left .right {
  width: var(--other-width);
}

.hover-left .right:before {
  z-index: 2;
}


.hover-right .right {
  width: var(--hover-width);
}

.hover-right .left {
  width: var(--other-width);
}

.hover-right .left:before {
  z-index: 2;
}

@media(max-width: 800px) {
  h1 {
    font-size: 2rem;
  }

  .buttonLink {
    width: 12rem;
  }
}

@media(max-height: 700px) {
  .buttonLink {
    top: 70%;
  }
}
